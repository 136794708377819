import { Button }  from "antd";
import { ClearOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined 
} from "@ant-design/icons";

const CartTotal = () => {
  return (
<div className="cart h-full max-h-[calc(100vh_-_90px)] flex flex-col">
    <h2 className="bg-blue-600 text-center py-4 text-white 
    font-bold tracking-wide"> Product List</h2>

    <ul className="cart-items px-2 flex flex-col gap-y-3 pt-2">

        <li className="cart-item flex justify-between">
         <div className="flex item-center">
            <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" 
            alt="" className="w-16 h-16 object-cover" />
             <div className="flex flex-col ml-2">
                <b>Esplesso</b>
                <span>12 OZ x 2</span>
              </div>
             </div>
                <div className="flex items-center gap-x-1">
                  <Button type="primary" 
                  size="small" 
                  className="w-full flex items-center justify-center !rounded-full" 
                  icon={<PlusCircleOutlined />}
                  danger>
                  </Button>
                  <span className="font-bold w-6 inline-block text-center"> 1 </span>
                  <Button
                    type="primary"
                    size="small"
                    className="w-full flex items-center justify-center !rounded-full"
                    icon={<MinusCircleOutlined />}
                  danger>
                  </Button>
                  </div>
        </li>
       
        <li className="cart-item flex justify-between">
         <div className="flex item-center">
            <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" 
            alt="" className="w-16 h-16 object-cover" />
             <div className="flex flex-col ml-2">
                <b>Esplesso</b>
                <span>12 OZ x 2</span>
              </div>
             </div>
                <div className="flex items-center gap-x-1">
                  <Button type="primary" 
                  size="small" 
                  className="w-full flex items-center justify-center !rounded-full" 
                  icon={<PlusCircleOutlined />}
                  danger>
                  </Button>
                  <span className="font-bold w-6 inline-block text-center"> 1 </span>
                  <Button
                    type="primary"
                    size="small"
                    className="w-full flex items-center justify-center !rounded-full"
                    icon={<MinusCircleOutlined />}
                  danger>
                  </Button>
                  </div>
        </li>



    </ul>

        <div className="cart-totals mt-auto">
          <div className="border-t border-b">
            <div className="flex justify-between p-2">
                <b>ยอดรวม</b>
                <span>397 B</span>
            </div> 
            
            <div className="flex justify-between p-2">
                <b>ส่วนลด</b>
                <span className="text-red-700">0.00 B</span>
            </div>
            </div>

            <div className="border-b mt-4">
            <div className="flex justify-between p-2">
                <b className="text-xl text-green-500">สุทธิ</b>
                <span className="text-xl">397 B</span>
             </div> 
             </div> 

             <div className="py-4 px-2">
             <Button type="primary" 
             size="large" 
             className="w-full">Payment</Button>

             <Button type="primary" 
             size="large" 
             className="w-full mt-2" 
             icon={<ClearOutlined />}
             danger>ยกเลิก</Button>
             </div> 

</div>
</div>

  );
};

export default CartTotal;
