import Header from "../components/Header/Header";
import { Table, Card  } from "antd";

const CustomerPage = () => {

  const dataSource = [
    {
      key: "1",
      name: "Miky",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "john",
      age: 65,
      address: "10 Downing Street",
    },
    {
      key: "3",
      name: "Tom",
      age: 52,
      address: "10 Downing Street",
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];



  return (
    <div>
      <Header />
      <div className="px-6">
        <h1 className="text-4xl font-bold text-center mb-4">ประวัติการขายสินค้า</h1>
        <Table dataSource={dataSource} columns={columns} bordered pagination={false}/>
        <div className="cart-total flex justify-end mt-4">
          <Card className="w-72">
          </Card>
        </div>
        

      </div>
    </div>
  );
};

export default CustomerPage;
