import { Form, Input,Button,Carousel } from "antd";
import { Link } from "react-router-dom";

const RegisterPage = () => {


  return(
  <div className='h-screen'>
    <div className='flex justify-between h-full'>
     <div className='xl:px-20 px-10 w-full flex flex-col h-full justify-center relative'>
        <h1 className='text-center text-5xl font-bold mb-2'> LOGO </h1>
        <Form layout="vertical">
          <Form.Item
            label="ชื่อผู้ใช้งาน"
            name={"username"}
            rules={[
              {
                required:true,
                message: "โปรดกรอกชื่อผู้ใช้",
              },
            ]}
          >
           <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name={"Email"}
            rules={[
              {
                required:true,
                message: "โปรดกรอกชื่อ Email",
              },
            ]}
          >
           <Input />
          </Form.Item>

          <Form.Item
            label="รหัสผ่าน"
            name={"password"}
            rules={[
              {
                required:true,
                message: "โปรดกรอก รหัสผ่าน",
              },
            ]}
          >
           <Input.Password />
          </Form.Item>

          <Form.Item
            label="ยืนยันรหัสผ่าน"
            name={"passwordAgain"}
            rules={[
              {
                required:true,
                message: "โปรดกรอกรหัสผ่าน อีกครั้ง",
              },
            ]}
          >
           <Input.Password />
          </Form.Item>
         
          <Form.Item >
           <Button type="primary" htmlType="submit" 
           className="w-full" size="large"> Login </Button>
          </Form.Item>
        </Form>
         <div className="flex justify-center absolute left-0 bottom-10 w-full">
          ยินดีต้อนรับใช้งาน POS System &nbsp;
          <Link to="/login" className="text-blue-700">
          เข้าสู่ระบบ  </Link>
          </div>     
  </div>

<div className="xl:w-4/6 lg:w-3/5 md:w-1/2 md:flex hidden bg-[#6c63ff] h-full">
  <div className="w-full h-full flex items-center">
    <div className="w-full">
     <Carousel className="!h-full">
      <div className="!flex flex-col items-center justify-center h-full mb-10">
        <img src="/images/sl-001.svg" 
        alt="" 
        className="w-[600px] h-[600px]"/>
        <h3 
          className="text-4xl text-white text-center font-bold">
          Responsive
        </h3>
        <p className="mt-5 text-2xl text-white text-center"> 
         โปรแกรมขายสินค้า รองรับ android และ IOS
        </p>
      </div>
      
      <div className="!flex flex-col items-center justify-center h-full mb-10">
      <img 
        src="/images/sl-002.svg" 
        alt="" 
        className="w-[600px] h-[600px]"
      />
        <h3 className="text-4xl text-white text-center font-bold">
          2
        </h3>
        <p className="mt-5 text-2xl text-white text-center"> 
         โปรแกรมขายสินค้า รองรับ android และ IOS
        </p>
      </div>

      <div className="!flex flex-col items-center justify-center h-full mb-10">
      <img 
        src="/images/sl-003.svg" 
        alt="" 
        className="w-[600px] h-[600px]"
      />
        <h3 className="text-4xl text-white text-center font-bold">
          3
        </h3>
        <p className="mt-5 text-2xl text-white text-center"> 
         โปรแกรมขายสินค้า รองรับ android และ IOS
        </p>
      </div>

      <div className="!flex flex-col items-center justify-center h-full mb-10">
      <img 
        src="/images/sl-004.svg" 
        alt="" 
        className="w-[600px] h-[600px]"
      />
        <h3 className="text-4xl text-white text-center font-bold">
          4
        </h3>
        <p className="mt-5 text-2xl text-white text-center"> 
         โปรแกรมขายสินค้า รองรับ android และ IOS
        </p>
      </div>


      </Carousel>
      </div>
      </div>
     </div>

  </div>;
</div>

 ); 
};

export default RegisterPage
