import { useState } from 'react';
import Header from "../components/Header/Header";
import { Table, Card  } from "antd";
import { Button } from "antd";
import CreateBill from "../components/cart/CreateBill";

const CartPage = () => {
    const [isModalOpen,setIsModalOpen] = useState(false);

  const dataSource = [
    {
      key: "1",
      name: "Miky",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "john",
      age: 65,
      address: "10 Downing Street",
    },
    {
      key: "3",
      name: "Tom",
      age: 52,
      address: "10 Downing Street",
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
  ];

  console.log(isModalOpen);

  return (
    <div>
      <Header />
      <div className="px-6">
        <Table dataSource={dataSource} columns={columns} bordered pagination={false}/>
        <div className="cart-total flex justify-end mt-4">
          <Card className="w-72">
            <div className="flex justify-between">
                <span>รวมเงิน</span>
                <span>397.00 B</span>
            </div>
            <div className="flex justify-between my-2">
                <span>ส่วนลด</span>
                <span className="text-red-600">0.00 B</span>
            </div>
            <div className="flex justify-between">
                <span>สุทธิ</span>
                <span>397.00 B</span>
            </div>
            <Button type="primary" 
             size="large" 
             className="w-full mt-4"
             onClick={()=> setIsModalOpen(true)}>Payment</Button>
          </Card>
        </div>
        
       <CreateBill isModalOpen={isModalOpen}
       setIsModalOpen={setIsModalOpen} />

      </div>
    </div>
  );
};

export default CartPage;
