import { Modal,Button } from "antd";

const PrintBill = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      title="ใบเสร็จรับเงิน"
      open={isModalOpen}
      footer={false}
      onCancel={() => setIsModalOpen(false)}
      width={800}
    >
      <section className="py-20 bg-black">
        <div className="max-w-5xl mx-auto bg-white px-6">
          <article className="overflow-hidden">
            <div className="logo">
              <h2 className="text-4xl font-bold text-slate-700">LOGO</h2>
            </div>
            <div className="bill-details">
              <div className="grid grid-cold-3 gap-12">
                <div className="text-md text-state-500">
                  <p className="font-bold">ใบเสร็จรับเงิน</p>
                  ที่อยู่
                  <p>369/54 หมู่บ้านแกรนดิตี้ </p>
                  <p>หมู่ 2 ต.บางบัวทอง </p>
                  <p>อ.บางบัวทอง จ.นนทบุรี 11110</p>
                </div>

                <div className="text-md text-state-500">
                  <p className="font-bold">เลขที่เอกสาร IV231201001</p>
                  <p>วันที่ 30/12/2023</p>
                  <p></p>
                  <p></p>
                </div>
              </div>
            </div>

            <div className="bill-table-area mt-8">
              <table className="min-w-full devide-y devide-state-500 overflow-hidden">
                <thead>
                  <tr className="border-b border-slate-200">
                    <th scope="col" className="py-3.5 text-left text-sm font-normal
                     text-slate-700 md:pl-0 sm:table-cell 
                    ">สินค้า</th>
                    <th scope="col" className="py-3.5 text-right text-sm font-normal
                     text-slate-700 md:pl-0 sm:table-cell
                    ">จำนวน</th>
                    <th scope="col" className="py-3.5 text-right text-sm font-normal
                     text-slate-700 md:pl-0 sm:table-cell
                    ">ราคา</th>
                    <th scope="col" className="py-3.5 text-right text-sm font-normal
                     text-slate-700 md:pl-0 sm:table-cell
                    ">สุทธิ</th>
                  </tr>
                </thead>
                <tbody>
                    
                    <tr className="border-b border-t border-slate-200">
                        <td className="py-4 sm:table-cell">
                            <span className="font-medium">คาปูชิโน่</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>1</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>500 B</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>500 B</span>
                        </td>
                    </tr>
                    <tr className="border-b border-t border-slate-200">
                        <td className="py-4 sm:table-cell">
                            <span className="font-medium">คาปูชิโน่</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>1</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>500 B</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>500 B</span>
                        </td>
                    </tr>
                    <tr className="border-b border-t border-slate-200">
                        <td className="py-4 sm:table-cell">
                            <span className="font-medium">คาปูชิโน่</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>1</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>500 B</span>
                        </td>
                        <td className="py-4 text-end">
                            <span>500 B</span>
                        </td>
                    </tr>


                </tbody>
                <tfoot>
                <tr>
                    <th className="text-right pt-4 sm:table-cell hidden" colSpan="3" scope="row" >
                      <span className="font-normal text-slate-700">  ยอดรวม
                      </span>
                    </th>
                    <th className="text-left pt-4 sm:hidden" scope="row"  colSpan="3" 
                    >
                      <p className="font-normal text-slate-700">ยอดรวม</p>
                    </th>
                    <th className="text-right pt-4" scope="row">
                      <span className="font-normal text-slate-700">
                       500₺
                      </span>
                    </th>
                  </tr>

                </tfoot>
              </table>
            </div>
            <div className="py-9">
                <div className="border-t pt-9 border-slate-200">
                <p className="text-sm font-light text-slate-700">
                    ขอบคุณที่ใช้บริการ
                </p>
                </div>

            </div>
          </article>
        </div>
      </section>
      <div className="flex justify-end mt-4">
        <Button type="primary" size="large">Print</Button>
      </div>
    </Modal>
  );
};

export default PrintBill;
