import React from 'react'

const Categories = () => {
  return (
    <ul className="flex gap-4 md:flex-col text-lg">
        <li className="bg-green-700 px-6 py-10
        text-white curser-pointer
        hover:bg-pink-700 transition-all 
        text-center min-w-[145px]">
            <span>เครื่องดื่ม</span>
        </li>
       
        <li className="bg-green-700 px-6 py-10
        text-white curser-pointer
        hover:bg-pink-700 transition-all 
        text-center min-w-[145px]">
            <span>มีนม</span>
        </li>
     
        <li className="bg-green-700 px-6 py-10
        text-white curser-pointer
        hover:bg-pink-700 transition-all 
        text-center min-w-[145px]">
            <span>อาหาร</span>
        </li>
        
        <li className="bg-green-700 px-6 py-10
        text-white curser-pointer
        hover:bg-pink-700 transition-all 
        text-center min-w-[145px]">
            <span>อาหารว่าง</span>
        </li>
        
        <li className="bg-green-700 px-6 py-10
        text-white curser-pointer
        hover:bg-pink-700 transition-all 
        text-center min-w-[145px]">
            <span>ปิ้งย่าง</span>
        </li>
       
        <li className="bg-green-700 px-6 py-10
        text-white curser-pointer
        hover:bg-pink-700 transition-all 
        text-center min-w-[145px]">
            <span>เบเกอรี่</span>
        </li>
      

    </ul>
    
  )
}

export default Categories
