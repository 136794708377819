import { 
  Form, 
  Input, 
  Modal,  
  Select, 
  Button,
  Card,
 } from "antd";

const CreateBill = ({isModalOpen,setIsModalOpen}) => {
 
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  return (
    <Modal title="รายละเอียดการชำระ" 
    open={isModalOpen} 
    footer={false}
    onCancel={() => setIsModalOpen(false)}
    >
    
    <Form name="bill_form"
      className="bill-form"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      layout={"vertical"} >

        <Form.Item 
        label="ร้านค้า" 
        name="shopName"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
          },
        ]}
      >

          <Select placeholder="เลือกร้านค้า">
            <Select.Option value="coffree">coffree</Select.Option>
            <Select.Option value="meenom">meenom</Select.Option>
            <Select.Option value="สเต็ก">สเต็ก</Select.Option>
            <Select.Option value="ไข่เยิ้ม">ไข่เยิ้ม</Select.Option>
          </Select>
        </Form.Item>  

        <Form.Item 
            label="ชื่อลูกค้า"
            name={"customerName"} 
            rules={[{ 
              required:true,
              message: "customerName Is required"
              },
            ]}
            >
            <Input placeholder="ชื่อลูกค้า" />
        </Form.Item>

        <Form.Item 
            rules={[{ required:true, message:"โปรดใส่เบอลูกค้า " }]}
            name={"phoneNumber"} 
            label="เบอร์ติดต่อ"
            >   
            <Input placeholder="ใส่เบอร์ติดต่อ" maxLength={10} />
        </Form.Item>
        <Form.Item 
        name={"deliveryName"} 
        label="เดลิเวอรี่" 
        rules={[{
          required:true ,
          message:"เดลิเวอรี่" 
        },
      ]}
        >
          <Select placeholder="เลือกเดลิเวรี่">
            <Select.Option value="ไลน์แมน">ไลน์แมน</Select.Option>
            <Select.Option value="panda">panda</Select.Option>
            <Select.Option value="grap">grap</Select.Option>
            <Select.Option value="robinhood">robinhood</Select.Option>
          </Select>
        </Form.Item>
          <Card >
            <div className="flex justify-between">
                <span>รวมเงิน</span>
                <span>397.00 B</span>
            </div>
            <div className="flex justify-between my-2">
                <span>ส่วนลด</span>
                <span className="text-red-600">0.00 B</span>
            </div>
            <div className="flex justify-between">
                <span>สุทธิ</span>
                <span>397.00 B</span>
            </div>
            <div className="flex justify-end">
            <Form.Item>
              <Button type="primary" 
              size="large" 
              className="mt-4"
              htmlType="submit" >
               payment
              </Button>
              </Form.Item>
            </div>
          </Card>
     </Form>  
    </Modal>
  );
};

export default CreateBill


