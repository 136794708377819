import React, { useState, useEffect } from 'react';
import Header from "../components/Header/Header";
import StatisticCard from "../components/Statistics/StatisticCard";
import { Area,Pie } from '@ant-design/plots';


const StatisticPage = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
      asyncFetch();
    }, []);

    const asyncFetch = () => {
        fetch('https://gw.alipayobjects.com/os/bmw-prod/360c3eae-0c73-46f0-a982-4746a6095010.json')
          .then((response) => response.json())
          .then((json) => setData(json))
          .catch((error) => {
            console.log('fetch data failed', error);
          });
      };

      const config = {
        data,
        xField: 'timePeriod',
        yField: 'value',
        xAxis: {
          range: [0, 1],
        },
      };


  return (
    <div>
      <Header />
      <div className="px-6 md:pb-0 pb-20">
        <h1 className="text-4xl font-bold text-center 
        mb-4">สรุปการขายสินค้า</h1>

        <div className="statistic-section">
            <h2 className="text-lg">
                ผู้ใช้งานระบบ {" "} <span className="text-green-700 font-bold 
                text-xl">admin</span>
            </h2>
            <div className="statistic-cards grid xl:grid-cols-5 md:grid-cols-2 my-10 
            md:gap-10 gap-4">
            <StatisticCard  
            title = {"ยอดขาย"}   
            amount={"2000.56"} 
            img={"images/money.png"}
            />
            <StatisticCard  
            title = {"จำนวนบิล"}  
            amount={"15"}      
            img={"images/bill.png"}
            />
            <StatisticCard 
            title = {"ลดคูปอง"}   
            amount={"0.00"}    
            img={"images/discount.png"}
            />
            <StatisticCard  
            title = {"ลดรายการ"}   
            amount={"0.00"}   
            img={"images/discount.png"}
            />
            <StatisticCard  
            title = {"ลดท้ายบิล"}  
            amount={"0.00"}    
            img={"images/discount.png"} 
            />

            </div>

        </div>

        <div className="flex justify-between gap-10 lg:flex-row flex-col items-center">
              <div>
                <Area {...config} />
              </div>
              
            </div>
          </div>
        </div>
    );
};

export default StatisticPage;
