

const Products = () => {
  return (
    <div className="products-wrapper grid grid-cols-card gap-4"
    >
       <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img border">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>
 
        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img border">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img border">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>


        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img border">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>


        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img border">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img border">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

        <div className="product-item border hover:shadow-lg cursor-pointer transition-all select-none">
            <div className="product-img">
                <img src="https://magnitudetms.com/88/88office/img-product/product_5fad553a3f14c.jpg" alt="" 
                className="h-28 object-cover w-full border-b"/>
            </div>
            <div className="product-info  flex flex-col p-3">
                <span className="font-bold">Esplasso</span>
                <span>16 Oz</span>
            </div>   
        </div>

    </div>

  )
}

export default Products
